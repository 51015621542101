import { renderMarkdown } from '~/functions/markdown';
import { sustainabilityBenchmarkTemplate1, sustainabilityBenchmarkTemplateStatement, sustainabilityBenchmarkTemplateStatementCopied } from '~/functions/sfdr';
export default defineComponent({
  setup: function setup() {},
  data: function data() {
    return {
      tooltipMessage: 'Copy to clipboard',
      renderMarkdown: renderMarkdown,
      sustainabilityBenchmarkTemplate1: sustainabilityBenchmarkTemplate1,
      sustainabilityBenchmarkTemplateStatement: sustainabilityBenchmarkTemplateStatement
    };
  },
  methods: {
    handleCopyToClipboard: function handleCopyToClipboard() {
      var _this = this;
      navigator.clipboard.writeText(sustainabilityBenchmarkTemplateStatementCopied).then(function () {
        _this.tooltipMessage = 'Copied!';
        setTimeout(function () {
          _this.tooltipMessage = 'Copy to clipboard';
        }, 3000);
      });
    }
  }
});